<template>
    <div class="contract-bargain-page">

        <a-row class="contract-bargain-account">
            <div :span="4"><span class="account-col-pref">账户余额</span><span class="account-col-value">0.00</span>
            </div>
            <div :span="4"><span class="account-col-pref">定金</span><span class="account-col-value">500.00</span>
            </div>
            <div :span="4"><span class="account-col-pref">合同数</span><span class="account-col-value">4</span>
            </div>
            <div :span="4"><span class="account-col-pref">普通课时</span><span class="account-col-value">50/100</span>
            </div>
            <div :span="4"><span class="account-col-pref">赠送课时</span><span class="account-col-value"> 10/20</span>
            </div>
        </a-row>
        <a-divider></a-divider>

        <a-row class="operator-row">
            <a-col :span="5"   >
                <a-button>课程合同</a-button>
                <a-button>定金凭证</a-button>

            </a-col>
            <a-col :span="14"   ></a-col>
            <a-col :span="5"   >
                <a-button class="operator-btn-right">定金凭证</a-button>
                <a-button class="operator-btn-right">交定金</a-button>
                <a-button class="operator-btn-right">...</a-button>
            </a-col>
        </a-row>
        <div>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                @change="handleTableChange"
            >
                <span slot="action" slot-scope="text, record">
                    <a class="operator" @click="openBindContractForm(text)" style="color: #108ee9;"  href="javascript:;"> 关联 </a>
                </span>
            </a-table>
        </div>



        <a-modal
            title="关联合同"
            :visible="showBindContract"
            :confirm-loading="contractListLoading"
            :width='800'
            @ok="bindContractSubmit"
            @cancel="bindContractCancel"
        >
            <a-table
                :columns="contractColumns"
                :data-source="contractTableData"
                :loading="contractListLoading"
                :row-selection="contractListSelection"
                :pagination="false"
            >
            </a-table>
        </a-modal>


        <a-modal
            title="提示"
            :visible="showBindContractPromote"
            :width='500'
            @ok="bindContractPromoteConfirm"
            @cancel="bindContractPromoteCancel"
        >
            <div> <a-icon width="100em" height="100em"   type="question-circle" style="color: #ffeb2e;font-size: 28px" theme="filled" />确认关联此合同吗？关联后定金金额自动归为改合同的收费金额且不可修改</div>
        </a-modal>



    </div>
</template>
<script>
	export default {
		name: 'ContractBargain',
		data() {
			return {
                loading:false,
                contractListLoading:false,
                showBindContract:false,
                showBindContractPromote:false,
                columns : [
                    {
                        title: '定金凭证',
                        dataIndex: 'ContractNo',
                        sorter: true,
                        width: 'auto',
                        scopedSlots: { customRender: 'ContractNo' },
                    },
                    {
                        title: '收费类型',
                        dataIndex: 'TransType',
                    },
                    {
                        title: '交易金额',
                        dataIndex: 'amount',
                    },
                    {
                        title: '交易时间',
                        dataIndex: 'TransTime',
                    },
                    {
                        title: '所属中心',
                        dataIndex: 'CenterName',
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateName',
                    },
                    {
                        title: '出纳',
                        dataIndex: 'TellerName',
                    },
                    {
                        title: '关联合同',
                        key: 'action',
                        scopedSlots: { customRender: 'action' },

                    },

                ],
                tableData:[
                    {
                        ContractNo:"Ct12345678"
                    }
                ],

                contractColumns : [
                    {
                        title: '合同',
                        dataIndex: 'ContractTitle',
                        width: 'auto',
                        scopedSlots: { customRender: 'ContractTitle' },
                    },
                    {
                        title: '收费标准',
                        dataIndex: 'TransType',
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateName',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'CreateTime',
                    },

                ],
                contractTableData:[
                    {
                        ContractNo:"Ct12345678",
                        ContractTitle:'TL-A0123456-基础能力',
                    }
                ],
                contractListSelection:{
                    columnTitle:'',

                }
            }
		},
		created() {
		},
		mounted() {

		},
		methods: {
            openBindContractForm(contractNo){
                    this.showBindContract=true
            },
            bindContractCancel(){
                this.showBindContract=false
            },

            bindContractSubmit(){
                this.showBindContractPromote=true
                //this.contractListLoading=true

            },


            bindContractPromoteCancel(){
                this.showBindContractPromote=false
            },

            bindContractPromoteConfirm(){
                this.showBindContractPromote=false
            },


            handleTableChange(){

            }
		}
	}
</script>
<style scoped lang="less">
	.contract-bargain-page {
        background-color: white;
        padding-top: 50px;
        padding-left: 50px;
        margin-bottom: 120px;
        .contract-bargain-account{
            display: flex;
            justify-items: center;
            justify-content: space-between	;
            .account-col-pref{
                margin-right: 20px;
            }
        }
        .operator-row{
            margin-bottom: 20px;
            .operator-btn-right{
                margin-left: 8px;
            }
        }

	}



</style>
